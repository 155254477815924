













import { useUser } from "@/store/user"

export default defineComponent({
  name: "Logout",
  setup(props, { root }) {
    const user = useUser()

    onMounted(() => {
      user.logout()
      root.$router.replace("/")
    })
  },
})
